<template>
  <mobile-screen
    v-if="filters && filters.length"
    :header="true"
    screen-class="search-filter-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="search-header icon-hea1">
        <template v-slot:left>
          <button @click="closeFilters">
            <icon icon="#cx-hea1-arrow-left" />
          </button>
        </template>
        <div class="component-title">
          {{ displayLabelName("search", "filters", "search-filters") }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="showAddPresetButton"
            :to="{ name: savePresetLinkName }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul
      v-if="userFilterPresets && userFilterPresets.length"
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse['presets'] ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("search", "filters", "custom-presets") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse['presets']
                ? (collapse['presets'] = false)
                : (collapse['presets'] = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section :class="collapse['presets'] ? 'collapsed' : 'expanded five'">
        <Draggable
          v-bind="dragOptions"
          :list="userFilterPresets.filter(el => el.selected)"
          item-key="ordering"
          tag="li"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <template #item="{element}">
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="
                selectFilterPreset(element.id);
                scrollToView($event, element);
              "
            >
              <span
                v-if="showAddPresetButton"
                @click.prevent="deletePreset(element.id)"
                class="follow-up-icons delete-preset"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="14"
                      height="14"
                      style="width: 14px; height: 14px;"
                    >
                      <use xlink:href="#cx-app1-delete-02"></use>
                    </svg>
                  </div>
                </span>
              </span>
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label cursor-pointer"
                  :class="{ 'p-0': showAddPresetButton }"
                  >{{ element.name }}</label
                >
              </div>
              <span v-if="element.selected" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </template>
        </Draggable>
        <Draggable
          v-bind="dragOptions"
          :list="userFilterPresets.filter(el => !el.selected)"
          item-key="ordering"
          tag="li"
          class="clebex-item-section-item no-bottom-border light no-separator"
        >
          <template #item="{element}">
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="selectFilterPreset(element.id)"
            >
              <span
                v-if="showAddPresetButton"
                @click.prevent="deletePreset(element.id)"
                class="follow-up-icons delete-preset"
              >
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="14"
                      height="14"
                      style="width: 14px; height: 14px;"
                    >
                      <use xlink:href="#cx-app1-delete-02"></use>
                    </svg>
                  </div>
                </span>
              </span>
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label cursor-pointer"
                  :class="{ 'p-0': showAddPresetButton }"
                  >{{ element.name }}</label
                >
              </div>
              <span v-if="element.selected" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </template>
        </Draggable>
      </section>
    </ul>

    <section v-if="sections && Object.entries(sections).length">
      <ul
        v-for="[key, section] in Object.entries(sections)"
        :key="key"
        class="clebex-item-section overflow-hidden"
      >
        <li
          class="clebex-item-section-item"
          :class="collapse[key] ? 'collapsed' : 'expanded'"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label larger-label">
                {{ key }}
                <span v-if="section[0].type === 'multi'" class="selected-total"
                  >({{
                    section.filter(el => el.selected).length > 0
                      ? section.filter(el => el.selected).length
                      : section.length
                  }}/{{ section.length }})</span
                >
              </label>
            </div>
            <span
              class="follow-up-icons cursor-pointer"
              @click="
                collapse[key] ? (collapse[key] = false) : (collapse[key] = true)
              "
            >
              <span class="follow-up-icon-item collapsable"
                ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
              /></span>
            </span>
          </div>
        </li>
        <section
          v-if="section[0].type !== 'number-range'"
          :class="collapse[key] ? 'collapsed' : 'expanded five'"
        >
          <li
            v-for="option in section.filter(
              opt =>
                opt.selected &&
                (opt.slug !== 'dates' ||
                  (opt.slug === 'dates' && opt.date_end)) &&
                (opt.slug !== 'intervals' ||
                  (opt.slug === 'intervals' &&
                    dateRangeHours &&
                    inInterval(opt.hoursInterval)))
            )"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light no-separator"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="
                selectFilter({
                  filter: option,
                  selected: option.selected ? false : true
                })
              "
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer"
                  >{{ option.name }}
                  <span
                    v-if="
                      option.slug === 'dates' &&
                        option.id === 1 &&
                        globalDateFormat
                    "
                    >({{ showDate(option.date_start) }}
                    -
                    {{ showDate(option.date_end) }})</span
                  ></label
                >
              </div>
              <span v-if="option.selected" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
          <li
            v-for="option in section.filter(
              opt =>
                !opt.selected &&
                (opt.slug !== 'dates' ||
                  (opt.slug === 'dates' && opt.date_end)) &&
                (opt.slug !== 'intervals' ||
                  (opt.slug === 'intervals' &&
                    dateRangeHours &&
                    inInterval(opt.hoursInterval)))
            )"
            :key="option.id"
            class="clebex-item-section-item no-bottom-border light no-separator"
          >
            <div
              class="clebex-item-content-wrapper cursor-pointer"
              @click="
                selectFilter({
                  filter: option,
                  selected: option.selected ? false : true
                });
                scrollToView($event, option);
              "
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label cursor-pointer"
                  >{{ option.name
                  }}<span
                    v-if="
                      option.slug === 'dates' &&
                        option.id === 1 &&
                        globalDateFormat
                    "
                    >({{ showDate(option.date_start) }}
                    -
                    {{ showDate(option.date_end) }})</span
                  ></label
                >
              </div>
              <span v-if="option.selected" class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <div class="svg-icon">
                    <svg
                      width="12"
                      height="12"
                      style="width: 12px; height: 12px;"
                    >
                      <use xlink:href="#cx-app1-checkmark"></use>
                    </svg>
                  </div>
                </span>
              </span>
            </div>
          </li>
        </section>
        <section
          v-if="section[0].type === 'number-range'"
          :class="collapse[key] ? 'collapsed' : 'expanded one'"
        >
          <div class="capacity-filter-input-section">
            <button
              class="capacity-filter-btn decrease-capacity"
              @click="decreaseCapacity(section[0])"
            >
              -
            </button>
            <div class="capacity-filter-input-wrapper clebex-section-input">
              <input type="text" v-model="numberRange" @input="preventText" />
            </div>
            <button
              class="capacity-filter-btn increase-capacity"
              @click="increaseCapacity(section[0])"
            >
              +
            </button>
          </div>
        </section>
      </ul>
    </section>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button class="action-btn" @click="clearAll">
              {{ displayLabelName("search", "filters", "clear-all") }}
            </button>
          </li>
          <li v-if="filters.find(el => el['select-all'])" class="action">
            <button class="action-btn" @click="selectAll">
              {{ displayLabelName("search", "filters", "select-all") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteFilterPreset"
      :confirm-button-label="
        displayLabelName('search', 'filters', 'delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('search', 'filters', 'delete-cancel-action')
      "
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("search", "filters", "delete-preset") }}
      </h3>
      <p class="text">
        {{ displayLabelName("search", "filters", "delete-preset-text") }}
        <br />
        {{ displayLabelName("search", "filters", "delete-preset-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";

export default {
  name: "SearchFilters",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Draggable: defineAsyncComponent(() => import("vuedraggable"))
  },
  data() {
    return {
      collapse: {},
      dragOptions: {
        animation: 150,
        delay: 1000,
        delayOnTouchOnly: true
      },
      showDeleteModal: false
    };
  },
  computed: {
    ...mapState("searchFilters", [
      "filters",
      "filterPresets",
      "showAddPresetButton"
    ]),
    ...mapGetters("settings", ["globalDateFormat"]),
    userFilterPresets() {
      if (this.filterPresets) {
        return this.filterPresets.filter(el => el.type === "user");
      }
      return [];
    },
    sections() {
      let newSections = {};
      if (this.filters && this.filters.length) {
        this.filters.forEach(filter => {
          if (newSections[filter.section]) {
            newSections[filter.section].push(filter);
          } else {
            newSections[filter.section] = [filter];
          }
        });
        return newSections;
      } else {
        return null;
      }
    },
    numberRange: {
      // getter
      get() {
        const numberRangeObject = this.filters.find(
          obj => obj.type === "number-range"
        );
        return numberRangeObject ? numberRangeObject.value : 0;
      },
      // setter
      set(value) {
        let updatedFilters = [...this.filters];
        let numberObject = updatedFilters.find(
          num => num.type === "number-range"
        );
        if (numberObject) {
          numberObject.value = value;
          this.$store.commit("searchFilters/setFilters", updatedFilters, {
            root: true
          });
        }
      }
    },
    dateRangeHours() {
      if (this.filters && this.filters.length) {
        return this.filters.find(el => el.selected && el.slug === "dates")
          .hoursInterval
          ? Math.round(
              this.filters.find(el => el.selected && el.slug === "dates")
                .hoursInterval
            )
          : null;
      } else {
        return null;
      }
    }
  },
  created() {
    if (
      !this.filterPresets ||
      !this.filterPresets.length ||
      !this.filters ||
      !this.filters.length
    ) {
      this.closeFilters();
      return;
    }
  },
  methods: {
    ...mapActions("searchFilters", [
      "selectFilter",
      "selectFilterPreset",
      "deleteFilterPreset",
      "unSelectFilterPresets"
    ]),
    selectAll() {
      this.unSelectFilterPresets();
      this.numberRange = 0;
      const updatedFilters = this.filters.map(x =>
        x["select-all"] ? { ...x, selected: true } : { ...x, selected: false }
      );
      this.$store.commit("searchFilters/setFilters", updatedFilters, {
        root: true
      });
      this.$store.commit("searchFilters/setFiltersChanged", true, {
        root: true
      });
    },
    clearAll() {
      this.unSelectFilterPresets();
      this.numberRange = 0;
      const updatedFilters = this.filters.map(x =>
        x.type === "single"
          ? x["clear-all"]
            ? { ...x, selected: false }
            : { ...x, selected: true }
          : x["clear-all"]
          ? { ...x, selected: false }
          : x
      );
      this.$store.commit("searchFilters/setFilters", updatedFilters, {
        root: true
      });
      this.$store.commit("searchFilters/setFiltersChanged", true, {
        root: true
      });
    },
    closeFilters() {
      this.$store.commit("searchFilters/setFiltersClosed", true, {
        root: true
      });
      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    },
    handleFilters(filter) {
      const updatedFilters = this.filters.map(x =>
        filter.excludes &&
        filter.excludes.length &&
        filter.excludes.some(slug => slug === x.slug)
          ? x.value && x.value > 0
            ? { ...x, value: 0 }
            : { ...x, selected: false }
          : x
      );
      this.$store.commit("searchFilters/setFilters", updatedFilters, {
        root: true
      });
      if (filter.includes && filter.includes.length) {
        let filters = JSON.parse(JSON.stringify(this.filters));
        filter.includes.forEach(slug => {
          if (
            !filters.filter(fil => fil.slug === slug && fil.selected).length
          ) {
            let firstOfSection = filters.find(fil => fil.slug === slug);
            if (firstOfSection) {
              firstOfSection.selected = true;
            }
          }
        });
        this.$store.commit("searchFilters/setFilters", filters, {
          root: true
        });
      }
    },
    increaseCapacity(filter) {
      if (Number(this.numberRange) || Number(this.numberRange) === 0) {
        this.numberRange = Number(this.numberRange) + 1;
        this.setNumberRangeFilter(filter);
      }
    },
    decreaseCapacity(filter) {
      if (Number(this.numberRange - 1) >= 0) {
        this.numberRange = Number(this.numberRange) - 1;
        this.setNumberRangeFilter(filter);
      }
    },
    setNumberRangeFilter(filter) {
      this.unSelectFilterPresets();
      this.handleFilters(filter);
      let updatedFilters = [...this.filters];
      let numberObject = updatedFilters.find(
        num => num.id === filter.id && num.slug === filter.slug
      );
      numberObject.value = this.numberRange;
      this.$store.commit("searchFilters/setFilters", updatedFilters, {
        root: true
      });
      this.$store.commit("searchFilters/setFiltersChanged", true, {
        root: true
      });
    },
    showDate(date) {
      return formatDate(date, this.globalDateFormat);
    },
    scrollToView(event, element) {
      if (element.type !== "single") {
        return;
      }
      event.path.find(el => el.className === "expanded five").scrollTop = 0;
    },
    deletePreset(id) {
      this.$store.commit("searchFilters/setPresetToDelete", id, {
        root: true
      });
      this.showDeleteModal = true;
    },
    inInterval(hoursInterval) {
      const isIntervalTrimSection = [
        "r_occupancy-min-average-max-search-filters",
        "r_occupancy-historical-occupancy-search-filters",
        "r_occupancy-historical-real-use-search-filters"
      ].includes(this.$route.name);
      if (!isIntervalTrimSection) {
        if (hoursInterval < this.dateRangeHours) {
          return true;
        } else {
          return false;
        }
      } else {
        if (hoursInterval <= this.dateRangeHours) {
          // If date range is more then a year show quarterly and yearly interval
          if (this.dateRangeHours >= 8761) {
            if (hoursInterval >= 2184) {
              return true;
            }
          }
          // If date range is 3-12 months show monthly and quarterly interval
          else if (this.dateRangeHours >= 2185) {
            if (hoursInterval >= 720) {
              return true;
            }
          }
          // If date range is 1-3 months show weekly and monthly intervals
          else if (this.dateRangeHours >= 721) {
            if (hoursInterval >= 168) {
              return true;
            }
          }
          // If date range is 15-30 days show weekly intervals
          else if (this.dateRangeHours >= 360) {
            if (hoursInterval >= 168) {
              return true;
            }
          }
          // If date range is 7-15 days show daily and weekly intervals
          else if (this.dateRangeHours >= 168) {
            if (hoursInterval >= 24) {
              return true;
            }
          }
          // If date range is 2-7 days show 6h and daily intervals
          else if (this.dateRangeHours >= 48) {
            if (hoursInterval >= 6) {
              return true;
            }
          }
          // If date range is less then 2 days show 1h, 6h and daily intervals
          else if (this.dateRangeHours >= 0) {
            if (hoursInterval >= 1) {
              return true;
            }
          }
          return false;
        } else {
          return false;
        }
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: false
    },
    savePresetLinkName: {
      type: String,
      required: false
    }
  }
};
</script>
